.profil__page h1,
h3 {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	color: #33302e;
}
.profil__page h1 {
	font-size: 31px;
}
.profil__page h3 {
	font-size: clamp(14px, 3vw, 25px);
}
.profil__page h4 {
	font-weight: 600;
	font-size: clamp(16px, 3vw, 20px);
	line-height: 150%;
	color: #66605c;
    margin-bottom: 1rem;
}
.user_info_value {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #523b28;
}

.user_info_label {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-transform: uppercase;
	color: #999591;
    margin: 0;
}
.btn-disable-account:hover{
	background-color: #C22A29;
	color: #FFF !important;
}