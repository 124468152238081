.all-offers-link,
.all-offers-link:hover {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	font-feature-settings: "liga" off;
	color: #c22a29;
}
h1.banner-title {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 3vw, 50px);
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #ffffff;
}
h2.banner-title {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(16px, 3vw, 23px);
	line-height: 122.5%;
	font-feature-settings: "liga" off;
	color: #ffffff;
}
.banner-text {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: clamp(12px, 3vw, 16px);
	line-height: 150%;
	font-feature-settings: "liga" off;
	color: #000000;
}
.btn-start {
	background: #ffffff;
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(12px, 2vw, 14px);
	line-height: 150%;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	font-feature-settings: "liga" off;
	color: #33302e;
	padding: 0.7rem 1.5rem;
	white-space: nowrap;
}
.all-offers h3 {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 3vw, 34px);
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #f2ba8c;
}
.sans-abonnement-infos p {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #000000;
}
.all-offers table thead tr th {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 900;
	font-size: clamp(20px, 3vw, 24px);
	line-height: 150%;
	text-align: left;
	color: rgba(0, 0, 0, 0.8);
	white-space: nowrap;
}
.all-offers table tbody tr td {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 218.5%;
	color: #000000;
	background-color: #f2f2f2;
	text-align: center;
}
.banner-img {
	width: 300px;
}
.payement-modal-title {
	font-family: "Montserrat" !important;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #06152b;
}
.mode-paiement-container {
	background: #f2f2f2;
	border: 2px solid #f2f2f2;
	padding: 0.7rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.mode-paiement-name {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
}
.mode-paiement-logo {
	font-size: 26px;
	color: #bdbdbd;
}
.mode-paiement-container.checked {
	border-color: #f2ba8c;
}
.mode-paiement-container.checked .mode-paiement-logo {
	color: #f2ba8c;
}
.border-payement-b,
.border-b {
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: 3px;
}
.form-payement-input {
	border: none;
	outline: none;
}
.payement-text,
.form-payement-input {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
}

.offers-cards {
	margin-top: -70px;
}
