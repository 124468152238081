.form-search-input {
	background: rgba(195, 160, 131, 0.15);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.5);
}
.form-search-input:focus {
	box-shadow: none;
	border-color: #E97D24 !important;
}