.total-pepite {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #999591;
}
.pepite__img {
	width: 100%;
	height: 150px;
	border-radius: 2px;
	object-fit: cover;
}
.pepite__title {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(20px, 2vw, 25px);
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
	margin-bottom: 10px;
}
.group-info h5,
.group-info h6 {
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
}
h5.group_info_title {
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #cccac8;
	margin-bottom: 0;
	margin-bottom: 5px;
}
.h6.group_info_value {
	color: #999591;
}
.btn__show {
	border: 1px solid #c3a083;
	border-radius: 2px;
	padding: 0.3rem 0.7rem;
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	align-items: center;
	font-feature-settings: "liga" off;
	color: #c3a083;
}
.btn__show:hover {
	background-color: #fff1e6;
	color: #33302e;
}
.group_info_statut {
	font-family: "Urbanist";
	font-style: normal;
	color: #999591;
}
.group_info_statut::before {
	content: "";
	display: block;
	background: #cccac8;
	width: 8px;
	height: 8px;
	position: absolute;
	left: 0px;
	top: 3px;
	border-radius: 50%;
}
.group_info_statut.online::before {
	background: #2eb75c !important;
}
.group_info_statut.ebauche::before {
	background: #CCCAC8 !important;
}
.group_info_statut.sales::before {
	background: #C22A29 !important;
}
.pepite-left-block::before {
	content: "";
	display: block;
	position: absolute;
	width: 1px;
	height: 64px;
	background-color: #cccac8;
	right: 0;
	top: 20%;
}
.pepite__item button.dropdown-toggle::after {
	display: none;
}
.pepite_action_container {
	border: none;
}
.btn_action {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-align: right;
	font-feature-settings: "liga" off;
}
.btn__edit {
	color: #999591;
}
.btn__edit:hover {
	color: #33302e;
}
.btn__delete {
	color: #c22a29;
}
.btn__delete:hover {
	color: #c22a29;
}
.logo-brodcast {
	width: 55px;
}
.description__container h5 {
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-transform: uppercase;
	color: #999591;
}
.description__text p, .description__text ul .description__text li {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #523b28;
}
.description__text ul {
	padding-left: 5px;
	margin-top: 5px;
}
.description__text li {
	display: block;
	margin-bottom: 1px;
	list-style: disc;
}
.detail__pepite .group_info_value {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #523b28;
}
.detail__pepite .group-info {
margin-bottom: 1rem;
}

.download-shopify-product h3{
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(16px, 3vw, 24px);
	line-height: 150%;
	text-align: left;
	font-feature-settings: "liga" off;
}
.import-steps-container li{
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(13px, 3vw, 16px);
	line-height: 150%;
	text-align: left;
	font-feature-settings: "liga" off;
  list-style: decimal;
}