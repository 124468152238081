.outlet__container {
	width: 100%;
	padding-top: 6rem;
}

.layout__dashboard h1 {
	font-size: 31px;
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #1a1817;
}
.layout__dashboard .title__level3,
.layout__dashboard .fas__label__imp {
	font-family: "Urbanist";
	font-weight: 700;
	font-size: 14px;
	line-height: 150%;
	color: #66605c;
}
.layout__dashboard .fas__label {
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	font-feature-settings: "liga" off;
	color: #999591;
}
.layout__dashboard .dashboard__card svg {
	fill: #f2ba8c;
	width: 12px !important;
	height: 13px !important;
}
.bg__slate {
	background: #fafafa;
}
@media (min-width: 992px) {
	.sidebar {
		width: 200px;
	}
	.outlet__container {
		width: calc(100% - 200px) !important;
		padding-right: 3rem;
		padding-left: 2rem;
	}
}
