@media (max-width: 575px) {
	.no-view-mobile {
		display: none;
	}
}
@media (max-width: 767px) {
	.view-md {
		display: none;
	}
}
@media (min-width: 768px) {
	.pepite__img {
		width: 100px !important;
		height: 100px !important;
	}
	.w-md-70 {
		width: 75% !important;
	}
	.banner-img {
		width: 378px !important;
		height: 236px !important;
	}
}
@media (max-width: 991px) {
	.view-lg {
		display: none;
	}
}
@media (min-width: 992px) {
	.w-lg-70 {
		width: 70%;
	}
	.w-lg-80 {
		width: 80%;
	}
	.pt-lg-6 {
		padding-top: 6rem !important;
	}
	.auth-form-container {
		padding-top: 12rem !important;
		padding-bottom: 9rem !important;
	}
	.view-lg {
		display: block;
	}
	.logo-container {
		justify-content: flex-start !important;
	}
	.auth-title-container {
		background-color: transparent !important;
		box-shadow: none !important;
	}
	.auth-title {
		font-size: 39px !important;
		line-height: 130% !important;
		text-align: left !important;
	}
	.auth-accroche-text {
		font-size: 18px !important;
		line-height: 27px !important;
		color: #66605c !important;
		font-weight: 700 !important;
	}
	.auth-link {
		color: #c3a083 !important;
	}
	.fas__popup__container {
		margin-top: 20vh !important;
	}
	.outlet__admin__container {
		width: calc(100% - 250px) !important;
		padding-right: 3rem;
		padding-left: 2rem !important;
		min-height: 100vh;
		padding-top: 8rem !important;
	}
}
@media (max-width: 1399px) and (min-width: 1200px) {
}
/* @media (min-width: 1200px) {
	.btn-caracteristiques-add {
		width: 20%;
	}
} */