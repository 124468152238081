.onboard__card {
	background: #ffffff;
	box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
		0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
}
.mode-paiement-item {
	border: 2px solid #ffffff;
}
.mode-paiement-item.active {
	border-color: #d38a4e !important;
}
.mode-paiement-item h5 {
	text-transform: capitalize;
	font-weight: 800;
	font-size: 20px;
	line-height: 150%;
	letter-spacing: 0.025em;
	color: #000;
}
.offer-item {
	border: 2px solid #eceae9;
	border-radius: 5px;
}

.fas-offer-title {
	font-size: clamp(16px, 3vw, 28px);
	line-height: 28px;
}
.fas-offer-description p {
	font-size: 16px;
	line-height: 20px;
}
.fas-offer-description {
	height: 40px;
	overflow: hidden;
	margin-bottom: 10px;
}
.offer-advantage-container li {
	font-size: 14px !important;
	line-height: 20px;
}
.offer-advantage-container {
	height: 180px;
	overflow: hidden;
}
.recommande-container {
	height: 25px;
}
.badge-recommande {
	background-color: #F2BA8C;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: #000;
	text-align: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: clamp(12px, 3vw, 14px);
    line-height: 21px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
}
.subscribe_btn:hover {
	transform: scale(1.1);
}