.add-seller-bloc-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #33302e;
	margin-bottom: 2rem;
}
.total-stock {
	font-family: "DM Sans";
	font-style: normal;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.react-switch {
	vertical-align: middle;
	margin-left: 4px;
}
.toggle-style-off {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 9px;
	color: #ffffff;
	padding-right: 2px;
	padding-left: 2px;
	background-color: #FA1414;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.react-switch-bg{
	background-color: transparent !important;
}
.toggle-style-on {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 9px;
	color: #ffffff;
	padding-right: 2px;
	background-color: #21A374;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
