.form-label {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #cccac8;
}

.form-custom-control {
	border: 1px solid #cccac8;
	border-radius: 2px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px !important;
	color: #999591;
	font-family: "Urbanist";
	font-style: normal;
}
.form-control-admin {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}
.form-floating > .form-control:focus + label,
.form-floating > .form-control:not(:placeholder-shown) + label {
	font-weight: 700;
	color: #c3a083;
}

.form-custom-control:focus,
.form-control-admin:focus {
	box-shadow: none;
	border-color: #c3a083 !important;
}
.form-icon:focus {
	border-right: 1px solid #c3a083 !important;
}
.fashion__btn {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(12px, 3vw, 14px);
	line-height: 21px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	font-feature-settings: "liga" off;
	text-align: center;
	white-space: nowrap;
}
.btn__primary,
.btn__danger {
	background: #fafafa;
	padding: 13.5px 27px;
	gap: 10px;
	border: none;
	color: #cccac8;
	transition: all ease-in-out 300ms;
}

.btn__primary.no-effect {
	background: #f2ba8c;
	color: #33302e;
}

.btn__primary:hover,
.btn__primary:focus,
.btn__add:focus,
.btn__add:hover {
	background: #f2ba8c;
	color: #33302e;
}
.btn__add {
	background: #f2ba8c;
	color: #33302e;
	padding: 13.5px 27px;
	gap: 10px;
	transition: all ease-in-out 300ms;
}
.btn__danger:hover {
	background-color: #c22a29 !important;
	color: #ffffff !important;
}

button.btn-back {
	font-weight: 700;
	line-height: 18px;
	gap: 5px;
}

.fashion__card {
	background: #ffffff;
	box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
		0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
	border-radius: 2px;
}
.fashion__link {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	font-feature-settings: "liga" off;
	color: #c3a083;
	text-decoration: none;
}
.fashion__link:hover {
	text-decoration: underline;
	color: #c3a083;
}
.fas__control {
	background: #ffffff;
	border: 1px solid #cccac8;
	border-radius: 2px;
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #cccac8;
	padding: 10px 10px 10px 1.8rem;
}
.fas__control:focus {
	box-shadow: none;
	border-color: #c3a083;
}
.fas__check {
	width: 24px;
	height: 24px;
	margin: 0;
	border: 1px solid #cccac8;
}
.fas__check:checked {
	background-color: #f2ba8c !important;
	border: 2px solid #c3a083;
	box-shadow: none;
}
.fas__check:focus {
	box-shadow: none !important;
	border: 1px solid #f2ba8c;
}
.form-first-label {
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: #66605c;
	margin-bottom: 3px;
}
.btn__disabled,
.btn__primary:hover.btn__disabled {
	background-color: #fafafa !important;
	border: #fafafa !important;
	color: #cccac8 !important;
}
.modal__title {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(16px, 3vw, 31px);
	line-height: 130%;
	text-align: center;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.alert__info {
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
	color: #620525;
}
.modal-ask-text {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: #66605c;
}
.icon__share svg {
	fill: #c3a083;
	width: 40px;
	height: 40px;
}
.common_style {
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
}
.common_style.text-lg {
	font-size: 17px !important;
}
.font_urbanist {
	font-family: "Urbanist";
}
.logout__btn {
	color: #cccac8;
	background: #fafafa;
}
.logout__btn:hover {
	color: #c22a29;
	background: #fbeee9;
}
.modal-ask-logout {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 16px !important;
	line-height: 150%;
	text-align: center;
	color: #66605c !important;
}
.btn_delete {
	color: #cccac8;
}
.btn_delete:hover {
	color: #c22a29;
}
.add__pepite_page h2,
.onboarding_page h2 {
	font-family: "Cormorant Garamond";
	font-weight: 700;
	font-size: clamp(18px, 4vw, 25px);
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.v-none {
	width: 0 !important;
	height: 0 !important;
}
.pagination .active > .page-link {
	background-color: #e97d24 !important;
}
.pagination .active > .page-link {
	border-color: #e97d24;
	color: #ffffff !important;
}
.pagination .page-link {
	color: rgba(0, 0, 0, 0.7) !important;
}
.modal-shadow {
	box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.7) !important;
}
.fashion-alert {
	border-left: 6px solid #c3a083;
	border-radius: 0.4rem;
	color: #33302e;
	padding: 2rem 1%;
	display: flex;
	align-items: center;
	gap: 5px;
}
/* #fff1e6 */
.fashion-alert-info {
	background-color: #fff1e6;
}
.fashion-alert h4 {
	margin: 0;
	font-family: "Urbanist";
	font-style: normal;
}
.breadcrumb-link {
	font-family: "DM Sans";
	font-weight: 500;
	font-size: 15;
	line-height: "20px";
}
.react-select-custom__control {
	min-height: 55px !important;
	border: 1px solid #cccac8;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px !important;
	color: #000000;
	font-family: "Urbanist";
	font-style: normal;
	border-radius: 0 !important;
}
.react-select-custom__control--is-focused {
	box-shadow: none !important;
	border-color: #c3a083 !important;
}
.market_tab {
	font-family: "Cormorant Garamond";
	color: #33302e;
	font-weight: 700;
	font-size: clamp(20px, 5vw, 24px);
	line-height: 24px;
}
.market_tab.active {
	color: #c3a083 !important;
}
.form-dynamic-input {
	padding: 1rem 1.5rem;
}
.btn-import {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.input-hidden-file {
	visibility: hidden;
	height: 0;
	width: 0;
}
.plateformes__logo_container.checked {
	border-color: #c3a083;
	border-width: 2px;
}
.contact-form .ql-editor {
	height: 140px !important;
}
