.abonnement-card-item * {
	font-family: "Urbanist" !important;
}
.fas-ab-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 0.1em;
	color: rgba(0, 0, 0, 0.7);
}

.fas-ab-price {
	font-weight: 900;
	font-size: 35px;
	text-align: center;
	color: #f2994a;
}

.fas-ab-description {
	font-family: "Urbanist";
	font-style: italic;
	font-weight: 300;
	font-size: 13px;
	line-height: 135%;
	text-align: center;
	color: rgba(0, 0, 0, 0.7);
}

.offer-item {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: #000000;
}
.text-desc {
	font-size: 11px;
	line-height: 45px;
}

.offer-info p {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #d38a4e;
}
.total-abonnes {
	font-weight: 400;
	font-size: 29.449px;
	line-height: clamp(20px, 2.5vw, 30px);
	text-align: right;
	text-transform: uppercase;
	color: #000000;
	white-space: nowrap;
}
.avantages-offres li{
	list-style: url("../../../../assets/images/check.png");
}
.abonnement-card-item{
	border: 2px solid #ffffff;
}
.abonnement-card-item.active {
	border-color: #FFF1E6 !important;
}