.paginations-container .page-item {
	margin-right: 6px;
}
.paginations-container a.page-link {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
	font-feature-settings: "liga" off;
	color: #cccac8;
    margin-left: 3px;
    border-radius: 0 !important;
    border: 2px #cccac8 solid;
	box-shadow: none !important;
}
.paginations-container .pagination .active>.page-link  {
    border: 2px solid #F2BA8C !important;
	background-color: #ffffff !important;
	color: rgba(0, 0, 0, .7) !important;
}