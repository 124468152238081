.total__value {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 700;
	font-size: 31px;
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #5a4c44;
}
.item__title {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	color: #bcb7b3;
}
