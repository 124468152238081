.contact-form p {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #000000;
}
.contact-form h5 {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	color: #f2ba8c;
}
