.gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(24, 3vh);
	gap: 10px;
}

.gallery--img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.gallery-col-1 {
	grid-column-start: 1;
	grid-column-end: 1;
}
.gallery-col-2 {
	grid-column-start: 2;
	grid-column-end: 2;
}
.gallery-col-3 {
	grid-column-start: 3;
	grid-column-end: 3;
}
.gallery-col-4 {
	grid-column-start: 4;
	grid-column-end: 4;
}
.gallerry-item--1 {
	grid-row-start: 1;
	grid-row-end: 1;
}

.gallerry-item--2 {
	grid-row-start: 1;
	grid-row-end: 4;
}

.gallerry-item--3 {
	grid-row-start: 1;
	grid-row-end: 7;
}
.gallerry-item--4 {
	grid-row-start: 2;
	grid-row-end: 8;
}

.gallerry-item--5 {
	grid-row-start: 2;
	grid-row-end: 8;
}

.gallerry-item--6 {
	grid-row-start: 4;
	grid-row-end: 11;
}

.gallerry-item--7 {
	grid-row-start: 7;
	grid-row-end: 14;
}
.gallerry-item--8 {
	grid-row-start: 8;
	grid-row-end: 14;
}

.gallerry-item--9 {
	grid-row-start: 8;
	grid-row-end: 14;
}
.gallerry-item--10 {
	grid-row-start: 11;
	grid-row-end: 18;
}
.gallerry-item--11 {
	grid-row-start: 14;
	grid-row-end: 20;
}

.gallerry-item--12 {
	grid-row-start: 14;
	grid-row-end: 20;
}
.gallerry-item--13 {
	grid-row-start: 14;
	grid-row-end: 20;
}
.gallerry-item--14 {
	grid-row-start: 18;
	grid-row-end: 23;
}
.gallerry-item--15 {
	grid-row-start: 20;
	grid-row-end: 24;
}
.gallerry-item--16 {
	grid-row-start: 20;
	grid-row-end: 24;
}
.gallerry-item--17 {
	grid-row-start: 20;
	grid-row-end: 24;
}