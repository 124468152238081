.admin-layout * {
	font-family: "DM Sans" !important;
}
.sidebar-admin {
	position: fixed;
	width: 250px;
	min-height: 100vh;
	max-height: 100vh;
	overflow-y: auto;
	z-index: 20 !important;
}
.sidebar-admin::-webkit-scrollbar{
	display: none;
	width: 8px;
}
.sidebar-admin:hover::-webkit-scrollbar{
	display: block;
}
/* Track */
.sidebar-admin::-webkit-scrollbar-track {
	border-radius: 10px;
}
.sidebar-admin::-webkit-scrollbar-thumb {
	background: #C3A083; 
	border-radius: 10px;
}

.sidebar-admin-link {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #c3a083;
}
.sidebar-admin {
	background: #ffffff;
}
.sidebar-admin svg {
	font-size: 18px;
}

.sidebar-admin-link:hover {
	color: #e97d24;
}
.sidebar-admin-link.active {
	color: #e97d24;
	font-weight: 700;
}
.sidebar-admin-link.active::before {
	content: "";
	display: block;
	width: 6px;
	height: 40px;
	background-color: #e97d24;
	border-radius: 10px 0px 0px 10px;
	position: absolute;
	top: -13px;
	right: 0;
}
.admin_btn_logout {
	color: #aeaeae;
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
    background: #FAFAFA;
    padding: 8px 14px;
}
.admin_btn_logout:hover {
    color: #c22a29;
    background: #fbeee9;
}
