.admin-layout h1 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 39px;
	color: #000000;
}
.admin-layout .chart_title {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
	color: #000000;
}
.outlet__admin__container {
	background: #faf9f7;
}
.filter_control {
	background: #ffffff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	border: 1px solid #ffffff;
	outline: none;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.4px;
	color: #252733;
	appearance: none;
	padding: 1rem 2rem 1rem 2rem;
}

.rounded-10 {
	border-radius: 10px;
}
.react-bootstrap-table table {
	table-layout: auto !important;
	border-collapse: collapse;
}
.admin-table table tbody tr td {
	font-family: "DM Sans" !important;
	font-style: normal;
	font-weight: 700 !important;
	font-size: 15px !important;
	line-height: 20px !important;
	color: rgba(0, 0, 0, 0.7) !important;
	padding: 22px 5px;
	border-right: 2px solid rgb(236, 229, 223) !important;
}
.admin-layout table thead tr th {
	font-family: "DM Sans" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 15px !important;
	line-height: 50px !important;
	text-align: justify !important;
	color: #010235 !important;
}
.admin-table table tbody tr {
	margin-bottom: 20px !important;
}
.admin-table table tbody tr td {
	background-color: #faf9f7 !important;
}
.subcategory-table table tbody tr td {
	background-color: #fafafa !important;
}
.sub-category-container {
	background-color: #fafafa !important;
}
.admin-table tbody,
td,
tfoot,
th,
thead,
tr {
	border-style: none !important;
	border-bottom: 10px solid #ffffff !important;
}
.action-btn {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 14px;
}
.action-btn-view {
	background: rgba(2, 180, 192, 0.1);
	color: #158a91;
}
.action-btn-view:hover {
	color: #158a91;
	background: rgba(2, 180, 192, 0.2);
}
.action-btn-delete {
	background-color: rgb(250, 20, 20, 0.1);
}
.action-btn-delete:hover {
	background-color: rgb(250, 20, 20, 0.2);
}
.action-btn-edit {
	background-color: rgba(233, 125, 36, 0.1);
}
.action-btn-edit:hover {
	background-color: rgba(233, 125, 36, 0.2);
}
.admin-btn-add {
	display: flex;
	align-items: center;
	background: #e97d24;
	border-radius: 5px;
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
	padding: 1rem;
}
.admin-btn-add:hover {
	color: #ffffff;
}
.admin-card-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 31px;
	color: #000000;
}

.admin-card-description,
.admin-card-description p {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.7);
}
.faq-total {
	font-weight: 500;
	font-size: 32px;
	line-height: 42px;
	color: #5e5b5b;
	margin: 0;
}
.faq-label-total {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #5e5b5b;
	margin: 0;
}
.author-name {
	font-weight: 400;
	font-size: 19px;
	line-height: 23px;
	letter-spacing: 0.4px;
	color: #000000;
}
.fas-modal-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
	color: #2d3748;
}
.abonnes-table table tbody tr td {
	font-weight: 400 !important;
}
.abonnes-btn-action {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	padding: 0.5rem 0.7rem;
	transition: all ease-in 100ms;
}
.btn-suspendre {
	color: rgba(0, 0, 0, 0.7);
	background: rgba(235, 237, 240, 0.9);
}
.btn-suspendre:hover {
	color: rgba(0, 0, 0, 1);
	background: rgba(235, 237, 240, 1);
}
.btn-abonne-delete {
	color: rgba(250, 20, 20, 0.9);
	background: rgba(250, 20, 20, 0.2);
}
.btn-abonne-delete:hover {
	color: rgba(250, 20, 20, 1);
	background: rgba(250, 20, 20, 0.3);
}
.admin-user-name {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	text-align: center;
}

.personal-info-title {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #333333;
}
.detail-profil-admin-page h6 {
	font-family: "Urbanist";
	font-style: normal;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.4px;
	text-transform: capitalize;
	color: #666666;
}
.custom-check-form-label {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
	margin-top: 4px;
}
.container-access {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	margin-bottom: 1rem;
	background: #ffffff;
	border: 2px solid #e1e1e1;
	border-radius: 10px;
	padding: 0.7rem 1rem;
}
.tabs-container li {
	list-style: none;
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: #2d3748;
	transition: all ease-in-out 300ms;
}
li.tab-active-item {
	color: #e97d24;
	font-weight: 600;
}
.frequence-label {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #828181 !important;
}
.params-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	color: rgba(0, 0, 0, 0.7);
	margin-bottom: 15px;
}
.carateritique-card-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 25px;
	color: #000000;
}
.btn-caracteristiques-add {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #e97d24;
	text-align: left;
}
.item-caracteristique {
	border: 2px solid rgba(0, 0, 0, 0.1);
	padding: 8px 10px;
}
.item-caracteristique h6 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.5);
	margin: 0;
}
.fas-admin-modal-title {
	font-family: "DM Sans" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 17px !important;
	line-height: 22px !important;
	color: #45595a !important;
}
