.stepper-item {
	border-radius: 1px;
	width: 8px;
	height: 8px;
}
.step-name {
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-align: right;
	font-feature-settings: "liga" off;
    margin: 0;
}
.stepper__bar {
    background-color: #ECEAE9;
    height: 40px;
    width: 1px;
    position: absolute;
    right: 4px;
}
