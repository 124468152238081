.faq_item_title {
	font-family: "Urbanist";
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	color: rgba(0, 0, 0, 0.85);
}
.faq__card__body p {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: rgba(60, 60, 67, 0.85);
	margin-bottom: 10px;
}
.faq__card [role=button] {
    width: 60%;
}