@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@200;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.primary-color {
	color: #f2ba8c;
}

body {
	overflow-x: hidden;
}

* {
	font-family: "Urbanist";
	font-style: normal;
	margin: 0;
	padding: 0;
}

p {
	margin: 0;
}

button {
	border: none;
	background-color: transparent;
}

h1,
h2,
h3,
h4 {
	font-family: "Cormorant Garamond";
	margin: 0;
}

h1 {
	color: #33302e;
	font-weight: 700;
	font-size: clamp(20px, 5vw, 39px);
	line-height: 51px;
}
li {
	list-style: none;
}
a {
	text-decoration: none;
}
.flex-c {
	display: flex;
	justify-content: center;
}
.flex-sb {
	display: flex;
	justify-content: space-between;
}
.flex-end {
	display: flex;
	justify-content: flex-end;
}

.flex-center {
	display: flex;
	justify-content: center;
}
.flex-m {
	display: flex;
	align-items: center;
}
.flex-mc {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-m-sb {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
/*** Font Size */
.fs-14 {
	font-size: 14px !important;
	line-height: 150%;
}
.fs-31 {
	font-size: clamp(16px, 3vw, 31px) !important;
}

/***COLORS**/
.color-marron {
	color: #523B28;
}
.color-gray {
	color: #999591;
}
.color-dark {
	color: #33302E !important;
}
.color-red {
	color: #C22A29 !important;
}
/***Font Weight**/
.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}
.fw-700 {
	font-weight: 700 !important;
}
.space-nowrap {
	white-space: nowrap;
}
.pb-20 {
	padding-bottom: 20rem;
}
/** React Quill*/
.editor-cgu .ql-container {
	min-height: 200px;
}

.ck-content {
	min-height: 200px;
}
