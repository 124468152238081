.info__message {
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
	color: #999591;
}
.sous__title {
	font-family: "Cormorant Garamond";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 130%;
	iletter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.plateformes__logo_container {
	background: #ffffff;
	border: 1px solid #cccac8;
	border-radius: 2px;
	padding: 0.5rem 0.5rem;
	transition: all ease-in 300ms;
	cursor: pointer;
}
.plateformes__logo_container.checked {
	border-color: #c3a083;
	border-width: 2px;
}
.success__message {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(12px, 3vw, 14px);
	line-height: 150%;
	letter-spacing: -0.01em;
	color: #2eb75c;
}
.btn-swal-close {
	font-family: "Urbanist" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 150% !important;
	letter-spacing: 0.15em !important;
	text-transform: uppercase !important;
	font-feature-settings: "liga" off !important;
	color: #999591 !important;
	border: 1px solid #cccac8 !important;
	background-color: #ffffff !important;
	border-radius: 0 !important;
	box-shadow: none !important	;
	width: 100% !important;
}
.validation__step .swal2-actions {
	width: 100% !important;
}
.swal__success__title {
	font-family: "Cormorant Garamond" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 31px !important;
	line-height: 130% !important;
	text-align: center !important;
	letter-spacing: -0.02em !important;
	font-feature-settings: "pnum" on, "lnum" on !important;
	color: #33302e !important;
}
.validation__step .swal2-html-container {
	font-family: "Urbanist" !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 150% !important;
	color: #66605c !important;
}
.boutique-items-container li {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(12px, 3vw, 14px);
	line-height: 150%;
	letter-spacing: -0.01em;
	cursor: pointer;
}
.boutique-items-container li:is(:hover) {
	background-color: rgba(195, 160, 131, 0.15);
}
.text-indicatif {
	font-family: "Urbanist";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(10px, 3vw, 12px);
	line-height: 150%;
	letter-spacing: -0.01em;
	color: #999591;
}
.text-result {
	color: #c22a29;
}
.token-market-status {
	width: 10px;
	height: 10px;
	background-color: #66605c;
	position: absolute;
	top: -4px;
	right: -4px;
	border-radius: 50%;
	animation: load 2s ease-out infinite;
}
.token-market-status.active {
	background-color: #2EB75C;
	animation: loadActive 2s ease-out infinite;
}
.token-market-status.unactive {
	background-color: #c22a29;
	animation: loadUnActive 2s ease-out infinite;
}
@keyframes load {
	0% {
		background: #66605c;
		border: 0px solid #fff;
	}
	50% {
		background: #66605c;
		border: 5px solid #66605c;
	}
	100% {
		background: #66605c;
		border: 0px solid #66605c;
	}
}
@keyframes loadActive {
	0% {
		background: #2EB75C;
		border: 0px solid #fff;
	}
	50% {
		background: #2EB75C;
		border: 5px solid #2EB75C;
	}
	100% {
		background: #2EB75C;
		border: 0px solid #2EB75C;
	}
}
@keyframes loadUnActive {
	0% {
		background: #c22a29;
		border: 0px solid #fff;
	}
	50% {
		background: #c22a29;
		border: 5px solid #c22a29;
	}
	100% {
		background: #c22a29;
		border: 0px solid #c22a29;
	}
}
