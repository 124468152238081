.sidebar {
	position: fixed;
	width: 250px;
	min-height: 100vh;
}
.sidebar-link {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	font-feature-settings: "liga" off;
	color: #cccac8;
}
.sidebar-link svg {
	fill: #cccac8;
}
.sidebar-link.active,
.sidebar-link:hover {
	color: #c3a083;
}
.sidebar-link.active::before,
.sidebar-link:hover::before {
	content: "";
	display: block;
	width: 2px;
	height: 22px;
	background-color: #c3a083;
	position: absolute;
	top: 0px;
	left: -8px;
}
.sidebar-link:hover svg,
.sidebar-link.active svg {
	fill: #c3a083;
}
.fas__popup__container {
	background: #fafafa;
	border-radius: 2px;
	margin-top: 10vh;
}
.popup__btn {
	width: 100%;
	background: #8c847d;
	font-weight: 700;
	font-size: 14px;
	line-height: 150%;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	font-feature-settings: "liga" off;
	color: #ffffff;
	padding: 12px 5px;
}
.popup__btn:hover {
	color: #ffffff;
}
.popup__message {
	font-weight: 700;
	font-size: 14px;
	line-height: 150%;
	color: #999591;
}
.popup__title {
	font-family: "Cormorant Garamond";
	font-weight: 700;
	font-size: 20px;
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
